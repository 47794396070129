<template>
  <v-autocomplete
    v-model="warehouseSelected"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :menu-props="{ offsetY: true }"
    :filter="warehouseFilter"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :return-object="returnObject"
    :full-width="fullWidth"
    :chips="chips"
    :small-chips="smallChips"
    :deletable-chips="deletableChips"
    :multiple="multiple"
    :outlined="outlined"
    :solo="solo"
    color="secondary"
    dense
    hide-details
    :loading="loading"
    :disabled="disabled" />
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String, Object, Array],
      default: null
    },
    label: {
      type: String,
      default: 'Warehouses'
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemText: {
      type: String,
      default: null
    },
    itemValue: {
      type: String,
      default: null
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    deletableChips: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    warehouseSelected: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('select', val)
      }
    }
  },
  methods: {
    warehouseFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.code?.toLowerCase() || ''
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    }
  }
}
</script>

<style scoped>

</style>
